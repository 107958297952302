export const SFilAbi = [
         {
           inputs: [
             {
               internalType: 'address[]',
               name: 'defaultOperators_',
               type: 'address[]',
             },
           ],
           stateMutability: 'nonpayable',
           type: 'constructor',
         },
         {
           anonymous: false,
           inputs: [
             {
               indexed: true,
               internalType: 'uint256',
               name: 'sid',
               type: 'uint256',
             },
             {
               indexed: true,
               internalType: 'address',
               name: 'addr',
               type: 'address',
             },
             {
               indexed: false,
               internalType: 'string',
               name: 'info',
               type: 'string',
             },
           ],
           name: 'ApplyStakingEvent',
           type: 'event',
         },
         {
           anonymous: false,
           inputs: [
             {
               indexed: true,
               internalType: 'address',
               name: 'owner',
               type: 'address',
             },
             {
               indexed: true,
               internalType: 'address',
               name: 'spender',
               type: 'address',
             },
             {
               indexed: false,
               internalType: 'uint256',
               name: 'value',
               type: 'uint256',
             },
           ],
           name: 'Approval',
           type: 'event',
         },
         {
           anonymous: false,
           inputs: [
             {
               indexed: true,
               internalType: 'address',
               name: 'operator',
               type: 'address',
             },
             {
               indexed: true,
               internalType: 'address',
               name: 'tokenHolder',
               type: 'address',
             },
           ],
           name: 'AuthorizedOperator',
           type: 'event',
         },
         {
           anonymous: false,
           inputs: [
             {
               indexed: true,
               internalType: 'address',
               name: 'operator',
               type: 'address',
             },
             {
               indexed: true,
               internalType: 'address',
               name: 'from',
               type: 'address',
             },
             {
               indexed: false,
               internalType: 'uint256',
               name: 'amount',
               type: 'uint256',
             },
             {
               indexed: false,
               internalType: 'bytes',
               name: 'data',
               type: 'bytes',
             },
             {
               indexed: false,
               internalType: 'bytes',
               name: 'operatorData',
               type: 'bytes',
             },
           ],
           name: 'Burned',
           type: 'event',
         },
         {
           anonymous: false,
           inputs: [
             {
               indexed: true,
               internalType: 'uint256',
               name: 'sid',
               type: 'uint256',
             },
             {
               indexed: true,
               internalType: 'address',
               name: 'addr',
               type: 'address',
             },
           ],
           name: 'DeleteStakingEvent',
           type: 'event',
         },
         {
           anonymous: false,
           inputs: [
             {
               indexed: true,
               internalType: 'uint256',
               name: 'sid',
               type: 'uint256',
             },
             {
               indexed: true,
               internalType: 'address',
               name: 'addr',
               type: 'address',
             },
             {
               indexed: false,
               internalType: 'uint256',
               name: 'sfilNum',
               type: 'uint256',
             },
           ],
           name: 'IssueStakingEvent',
           type: 'event',
         },
         {
           anonymous: false,
           inputs: [
             {
               indexed: true,
               internalType: 'address',
               name: 'operator',
               type: 'address',
             },
             {
               indexed: true,
               internalType: 'address',
               name: 'to',
               type: 'address',
             },
             {
               indexed: false,
               internalType: 'uint256',
               name: 'amount',
               type: 'uint256',
             },
             {
               indexed: false,
               internalType: 'bytes',
               name: 'data',
               type: 'bytes',
             },
             {
               indexed: false,
               internalType: 'bytes',
               name: 'operatorData',
               type: 'bytes',
             },
           ],
           name: 'Minted',
           type: 'event',
         },
         {
           anonymous: false,
           inputs: [
             {
               indexed: true,
               internalType: 'address',
               name: 'operator',
               type: 'address',
             },
             {
               indexed: true,
               internalType: 'address',
               name: 'tokenHolder',
               type: 'address',
             },
           ],
           name: 'RevokedOperator',
           type: 'event',
         },
         {
           anonymous: false,
           inputs: [
             {
               indexed: true,
               internalType: 'address',
               name: 'operator',
               type: 'address',
             },
             {
               indexed: true,
               internalType: 'address',
               name: 'from',
               type: 'address',
             },
             {
               indexed: true,
               internalType: 'address',
               name: 'to',
               type: 'address',
             },
             {
               indexed: false,
               internalType: 'uint256',
               name: 'amount',
               type: 'uint256',
             },
             {
               indexed: false,
               internalType: 'bytes',
               name: 'data',
               type: 'bytes',
             },
             {
               indexed: false,
               internalType: 'bytes',
               name: 'operatorData',
               type: 'bytes',
             },
           ],
           name: 'Sent',
           type: 'event',
         },
         {
           anonymous: false,
           inputs: [
             {
               indexed: true,
               internalType: 'address',
               name: 'from',
               type: 'address',
             },
             {
               indexed: true,
               internalType: 'address',
               name: 'to',
               type: 'address',
             },
             {
               indexed: false,
               internalType: 'uint256',
               name: 'value',
               type: 'uint256',
             },
           ],
           name: 'Transfer',
           type: 'event',
         },
         {
           inputs: [
             { internalType: 'address', name: 'adminAddr', type: 'address' },
           ],
           name: 'AddAdmin',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address[]', name: 'addrs', type: 'address[]' },
           ],
           name: 'AddBlackList',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address[]', name: 'addrs', type: 'address[]' },
           ],
           name: 'AddWhiteList',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [{ internalType: 'address', name: '', type: 'address' }],
           name: 'AddrToStakingID',
           outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [{ internalType: 'string', name: 'data', type: 'string' }],
           name: 'ApplyStaking',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [
             {
               internalType: 'enum SFil.Erc777ModeType',
               name: 'mode',
               type: 'uint8',
             },
           ],
           name: 'ChangeMode',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address', name: 'suAdminAddr', type: 'address' },
           ],
           name: 'ChangeSuperAdmin',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address', name: 'adminAddr', type: 'address' },
           ],
           name: 'DelAdmin',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address[]', name: 'addrs', type: 'address[]' },
           ],
           name: 'DelBlackList',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address[]', name: 'addrs', type: 'address[]' },
           ],
           name: 'DelWhiteList',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [],
           name: 'DeleteStakingApply',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [{ internalType: 'uint256', name: 'sid', type: 'uint256' }],
           name: 'DeleteStakingByAdmin',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address[]', name: 'addrs', type: 'address[]' },
           ],
           name: 'FreezeAddr',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [],
           name: 'GetNowStakingApply',
           outputs: [
             {
               components: [
                 { internalType: 'address', name: 'Addr', type: 'address' },
                 { internalType: 'string', name: 'Info', type: 'string' },
                 { internalType: 'uint256', name: 'validIdx', type: 'uint256' },
                 { internalType: 'uint256', name: 'SID', type: 'uint256' },
               ],
               internalType: 'struct SFil.StakingInfo[]',
               name: 'results',
               type: 'tuple[]',
             },
           ],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'uint256', name: 'sid', type: 'uint256' },
             { internalType: 'uint256', name: 'sfilNum', type: 'uint256' },
           ],
           name: 'IssueStaking',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [],
           name: 'StakingID',
           outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
           name: 'StakingInfoMap',
           outputs: [
             { internalType: 'address', name: 'Addr', type: 'address' },
             { internalType: 'string', name: 'Info', type: 'string' },
             { internalType: 'uint256', name: 'validIdx', type: 'uint256' },
             { internalType: 'uint256', name: 'SID', type: 'uint256' },
           ],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address[]', name: 'addrs', type: 'address[]' },
           ],
           name: 'UnfreezeAddr',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
           name: 'ValidStakingID',
           outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [],
           name: 'ValidStakingLen',
           outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [{ internalType: 'address', name: '', type: 'address' }],
           name: 'admins',
           outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address', name: 'holder', type: 'address' },
             { internalType: 'address', name: 'spender', type: 'address' },
           ],
           name: 'allowance',
           outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address', name: 'spender', type: 'address' },
             { internalType: 'uint256', name: 'value', type: 'uint256' },
           ],
           name: 'approve',
           outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address', name: 'operator', type: 'address' },
           ],
           name: 'authorizeOperator',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address', name: 'tokenHolder', type: 'address' },
           ],
           name: 'balanceOf',
           outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [{ internalType: 'address', name: '', type: 'address' }],
           name: 'blacklist',
           outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'uint256', name: 'amount', type: 'uint256' },
             { internalType: 'bytes', name: 'data', type: 'bytes' },
           ],
           name: 'burn',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [],
           name: 'decimals',
           outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
           stateMutability: 'pure',
           type: 'function',
         },
         {
           inputs: [],
           name: 'defaultOperators',
           outputs: [
             { internalType: 'address[]', name: '', type: 'address[]' },
           ],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [],
           name: 'erc777Mode',
           outputs: [
             {
               internalType: 'enum SFil.Erc777ModeType',
               name: '',
               type: 'uint8',
             },
           ],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [],
           name: 'granularity',
           outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address', name: 'operator', type: 'address' },
             { internalType: 'address', name: 'tokenHolder', type: 'address' },
           ],
           name: 'isOperatorFor',
           outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [],
           name: 'name',
           outputs: [{ internalType: 'string', name: '', type: 'string' }],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address', name: 'account', type: 'address' },
             { internalType: 'uint256', name: 'amount', type: 'uint256' },
             { internalType: 'bytes', name: 'data', type: 'bytes' },
             { internalType: 'bytes', name: 'operatorData', type: 'bytes' },
           ],
           name: 'operatorBurn',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address', name: 'sender', type: 'address' },
             { internalType: 'address', name: 'recipient', type: 'address' },
             { internalType: 'uint256', name: 'amount', type: 'uint256' },
             { internalType: 'bytes', name: 'data', type: 'bytes' },
             { internalType: 'bytes', name: 'operatorData', type: 'bytes' },
           ],
           name: 'operatorSend',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address', name: 'operator', type: 'address' },
           ],
           name: 'revokeOperator',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address', name: 'recipient', type: 'address' },
             { internalType: 'uint256', name: 'amount', type: 'uint256' },
             { internalType: 'bytes', name: 'data', type: 'bytes' },
           ],
           name: 'send',
           outputs: [],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [],
           name: 'superAdmin',
           outputs: [{ internalType: 'address', name: '', type: 'address' }],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [],
           name: 'symbol',
           outputs: [{ internalType: 'string', name: '', type: 'string' }],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address', name: 'operator', type: 'address' },
             { internalType: 'address', name: 'from', type: 'address' },
             { internalType: 'address', name: 'to', type: 'address' },
             { internalType: 'uint256', name: 'amount', type: 'uint256' },
             { internalType: 'bytes', name: 'userData', type: 'bytes' },
             { internalType: 'bytes', name: 'operatorData', type: 'bytes' },
           ],
           name: 'tokensReceived',
           outputs: [],
           stateMutability: 'pure',
           type: 'function',
         },
         {
           inputs: [],
           name: 'totalSupply',
           outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
           stateMutability: 'view',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address', name: 'recipient', type: 'address' },
             { internalType: 'uint256', name: 'amount', type: 'uint256' },
           ],
           name: 'transfer',
           outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [
             { internalType: 'address', name: 'holder', type: 'address' },
             { internalType: 'address', name: 'recipient', type: 'address' },
             { internalType: 'uint256', name: 'amount', type: 'uint256' },
           ],
           name: 'transferFrom',
           outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
           stateMutability: 'nonpayable',
           type: 'function',
         },
         {
           inputs: [{ internalType: 'address', name: '', type: 'address' }],
           name: 'whitelist',
           outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
           stateMutability: 'view',
           type: 'function',
         },
       ]