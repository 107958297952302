<template>
  <div class="item" @click.capture="showMask = true" :style="getStyle(index)">
    <div class="item-title">
      <span>APY: {{ getApy() | rate }}%</span>
      <span class="item-date">
        {{ info.Days != 0 ? info.Days + ' ' + $t('day') : $t('onDemand') }}
      </span>
    </div>
    <div class="item-rate">
      <span class="icon CRFI"></span>
      <span>
        <!-- {{ info.CRFIInterestRate | rate }}% -->
        CRFI: {{ getRate(info) | rate }}%
      </span>
      <span>+</span>
      <span class="icon cFIL"></span>
      <span>
        cFIL:
        <!-- {{ info.CFilInterestRate | rate }}% -->
        {{ getCFilRate(info) | rate }}%
      </span>
    </div>
    <div class="line"></div>
    <div class="item-total">
      <p>{{ $t('totalDeposit') }}</p>
      <p class="item-total-number">
        {{ info.Total | decimals }}
        <!-- 11{{ info.Amount || 0 | decimals }} {{ info.Type == 0 ? 'CRFI' : 'CFIL' }} -->
        <span class="item-total-type">{{
          info.Type == 0 ? 'CRFI' : 'cFIL'
        }}</span>
      </p>
    </div>
    <van-overlay
      class="mask"
      :show="showMask"
      @click.self="showMask = false"
      z-index="9999"
    >
      <div class="mask-content">
        <van-icon
          name="cross"
          color="#8790A9"
          class="close"
          @click.self="showMask = false"
        />
        <h4 class="mask-title">{{ $t('purchase') }}</h4>
        <div class="mask-item">
          <span class="mask-label">{{ $t('cycle1') }}</span>
          <p class="mask-item-text">
            {{ info.Days != 0 ? info.Days + ' ' + $t('day') : 'On Demand' }}
          </p>
        </div>
        <div class="mask-item">
          <span class="mask-label">{{ $t('profit1') }}</span>
          <p class="mask-item-text">
            <span>CRFI: {{ getRate(info) | rate }}%</span>
            <span> + </span>
            <span>cFIL: {{ getCFilRate(info) | rate }}%</span>
          </p>
        </div>
        <!-- <div class="mask-text">
          <p>
            {{ info.Days != 0 ? info.Days + ' ' + $t('day') : 'On Demand' }}
          </p>
          <div class="mask-rate">
            <span class="icon CRFI"></span>
            <span>CRFI: {{ getRate(info) | rate }}%</span>
            <span> - </span>
            <span class="icon cFIL"></span>
            <span>CFIL: {{ getCFilRate(info) | rate }}%</span>
          </div>
        </div> -->
        <!-- <div class="mask-desc">
          <p class="mask-desc-balance">
            {{
              info.Type == 1 ? wallet.walletCFil : wallet.walletCRFI | decimals
            }}
          </p>
        </div> -->
        <div class="form">
          <van-field
            class="field"
            center
            type="number"
            clearable
            v-model="value"
            :placeholder="$t('purchaseAmount')"
          />
        </div>
        <p class="mask-desc">
          <span class="icon" :class="info.Type == 0 ? 'CRFI' : 'cFIL'"> </span>
          <span>
            {{ info.Type == 0 ? 'CRFI' : 'cFIL' }} {{ $t('balance') }}
          </span>
          <span>{{
            info.Type == 1 ? wallet.walletCFil : wallet.walletCRFI | decimals
          }}</span>
        </p>
        <div class="confirm" @click="handleConfirm">
          {{ $t('confirm') }}
        </div>
        <!-- <div class="footer">
          <div class="footer-btn" @click="showMask = false">
            {{ $t('cancel') }}
          </div>
          <div class="footer-btn" @click="handleConfirm">
            {{ $t('confirm') }}
          </div>
        </div> -->
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import BigNumber from 'bignumber.js'
export default {
  name: 'BaseFooter',
  props: {
    index: {
      type: Number,
    },
    title: {
      type: String,
      default: 'depositCoins',
    },
    info: {
      type: Object | Array,
      default: () => {
        return {
          ID: 0,
          Type: 0,
          Days: 90,
          CRFIInterestRate: 2.7,
          CFilInterestRate: 3.2,
        }
      },
    },
  },
  data() {
    return {
      showMask: false,
      value: '',
    }
  },
  computed: {
    cfilPrice() {
      return this.$store.state.cfilPrice
    },
    crfiPrice() {
      return this.$store.state.crfiPrice
    },
    wallet() {
      return this.$store.state.wallet
    },
    balance() {
      return this.$store.state.balance
    },
  },
  async mounted() {
    let invite = this.getQueryString('invite') || ''
    this.inviteValue = invite
  },
  methods: {
    ...mapMutations(['setUserAddress']),
    ...mapActions(['initData', 'buyCoin', 'demandBuyCoin', 'getKeyValue']),
    getQueryString(name) {
      let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      let r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return decodeURIComponent(r[2])
      }
      return null
    },
    getApy() {
      let crfi = this.getRate(this.info)
      let cfil = this.getCFilRate(this.info)

      return new BigNumber(crfi).plus(new BigNumber(cfil))
    },
    getRate(data) {
      let { Type, Amount = 1, CRFIInterestRate, CRFIInterestRateDyn } = data
      if (Amount == 0) {
        Amount = 1
      }

      if (Type == 1) {
        // CFil
        if (this.crfiPrice == 1 || this.cfilPrice == 1) {
          return CRFIInterestRateDyn
        }
        let result = new BigNumber(this.crfiPrice)
          .times(new BigNumber(CRFIInterestRateDyn))
          .div(new BigNumber(this.cfilPrice))
        return result.toString()
      } else {
        return CRFIInterestRateDyn
      }
    },
    getCFilRate(data) {
      let { Type, CFilInterestRate } = data
      if (Type == 0) {
        if (this.crfiPrice == 1 || this.cfilPrice == 1) {
          return CFilInterestRate
        }
        let result = new BigNumber(this.cfilPrice)
          .times(new BigNumber(CFilInterestRate))
          .div(new BigNumber(this.crfiPrice))
        return result.toString()
      } else {
        return CFilInterestRate
      }
    },
    getStyle(index) {
      let target = index % 5
      let arr = ['#F57620', '#B406C3', '#3655E7', '#7CB732', '#6D06C3']
      return `background-color: ${arr[target]}`
    },
    handleConfirm() {
      if (!this.value) {
        this.$toast(this.$t('toast'))
        return
      }

      this.buyCoin({
        ...this.info,
        value: this.value,
        inviteValue: this.inviteValue,
      })
      // if (!this.info.ID) {
      //   this.demandBuyCoin({
      //     ...this.info,
      //     value: this.value,
      //     inviteValue: this.inviteValue,
      //   })
      // } else {
      // }
      this.showMask = false
      this.value = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 265px;
  // height: 88px;
  background: #6d06c3;
  background-image: url('../../assets/images/bowen.png') !important;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto 9px;
  opacity: 1;
  padding: 16px;
  border-radius: 13px;
  font-size: 13px;
  color: #fff;
  &-title {
    display: flex;
    justify-content: space-between;
  }
  &-date {
    font-size: 12px;
    font-family: Montserrat;
    font-weight: bold;
    line-height: 15px;
    color: #ffffff;
  }

  &-rate {
    display: flex;
    align-items: center;
    padding: 10px 0 16px 0;
    // border-bottom: 1px solid #c1c1c1;
    font-family: Segoe UI;
    font-weight: bold;
    line-height: 16px;
    color: #ffffff;
    text-align: left;
  }
  .line {
    height: 1px;
    background: #ffffff;
    opacity: 0.1;
  }
  &-total {
    margin-top: 10px;
    text-align: left;
    font-size: 12px;
    font-family: Segoe UI;
    font-weight: 600;
    line-height: 13px;
    color: #ffffff;
    &-number {
      margin-top: 4px;
      font-weight: 500;
      font-size: 22px;
      line-height: 100%;
      color: #ffffff;
    }
    &-type {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #ffffff;
    }
  }
}
.icon {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 4px;
}
.cFIL {
  background: url('../../assets/icon/cFIL-white.png') no-repeat;
  background-size: cover;
}
.CRFI {
  background: url('../../assets/icon/CRFI-white.png') no-repeat;
  background-size: cover;
}

.mask {
  &-content {
    width: 320px;
    position: absolute;
    z-index: 99;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    background: #414c5b;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-bottom: 20px;
    border-radius: 12px;
    font-size: 13px;
    font-family: Segoe UI;
    font-weight: bold;
    line-height: 16px;
    color: #ffffff;
  }
  .close {
    position: absolute;
    right: 18px;
    top: 18px;
  }
  &-title {
    text-align: left;
    margin: 0 18px;
    padding: 18px 0;
    border-bottom: 0.5px solid #4c5a7f;
  }
  &-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 18px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #c2c7d4;
    &-text {
      font-weight: 500;
      font-size: 14px;
      text-align: left;
      text-transform: capitalize;
      color: #ffffff;
    }
  }
  &-text {
    margin-left: 18px;
    margin-bottom: 24px;
    text-align: left;
  }
  &-desc {
    text-align: left;
    margin-bottom: 16px;
    margin-left: 18px;
    font-size: 10px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    &-name {
      display: flex;
      align-items: center;
    }
    &-balance {
      width: 150px;
      height: 24px;
      line-height: 24px;
      background: #2c3546;
      margin-top: 10px;
      border-radius: 10px;
    }
  }
  .confirm {
    height: 56px;
    line-height: 56px;
    margin: 0 18px;
    background: #5acbd0;
    border-radius: 12px;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
  }
  .footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    &-btn {
      height: 32px;
      margin: 0 14px;
      line-height: 32px;
      background: #fff;
      color: #1f8aff;
      border-radius: 22px;
      font-size: 15px;
      font-family: Segoe UI;
      font-weight: 600;
      color: #1f8aff;
    }
  }
}
.form {
  padding: 0 16px;
}
.field {
  height: 42px;
  line-height: 42px;
  background: #2c3546;
  margin-bottom: 10px;
  color: #fff;
  border-radius: 10px;
}
/deep/ .van-field__control {
  color: #fff;
}
</style>
